import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SubSink } from 'subsink';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import 'moment-timezone';

import { StorageService } from '../shared/services/storage.service';
import { AppStorage, MessageFor } from '../providers/enum';
import { SupportComponent } from '../support/support.component';
import { StreamDetailsService } from '../shared/services/stream-details.service';
import { FetchJsonService } from '../shared/services/fetch-json.service';
import { LiveVideoPlayerComponent } from '../live-video-player/live-video-player.component';
import { ResendCodeComponent } from '../resend-code/resend-code.component';
import { StaticTicketCode } from '../providers/constant';
import { VimeoPlayerComponent } from '../vimeo-player/vimeo-player.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  //#region variables

  public modalRef: BsModalRef;
  public userData: any;
  public streamDetails: any;
  public isTimeLeftLessOrEqual15: boolean;
  public errorMessage: string;
  public showLoading: boolean = false;
  public ticketCodeMessage: any;
  public isCheckValidation = false;
  public ticketCodeFormGroup: FormGroup;

  private subs = new SubSink();

  //#endregion

  //#region life-cycle hook

  constructor(private formBuilder: FormBuilder,
    private fetchJsonService: FetchJsonService,
    private modalService: BsModalService,
    private storageService: StorageService,
    private streamDetailsService: StreamDetailsService) { }

  ngOnInit(): void {
    //this.setStreamDetails();
   // this.getTicketCodeMessage();
    this.createTicketCodeFormGroup();
  }

  //#endregion

  //#region private methods

  private setTimer() {
    let startTime = moment.tz(this.streamDetails.eventStartDateTime, this.streamDetails.eventTimeZone).format();
    let startDuration = moment.duration(moment(startTime).diff(moment(new Date())));
    let startDays = startDuration.days();
    let startHours = startDuration.hours();
    let startMin = startDuration.minutes();

    let endTime = moment.tz(this.streamDetails.eventEndDateTime, this.streamDetails.eventTimeZone).format();
    let endTimeDuration = moment.duration(moment(endTime).diff(moment(new Date())));
    let endDays = endTimeDuration.days();
    let endHours = endTimeDuration.hours();
    let endMin = endTimeDuration.minutes();

    if (endDays <= 0 && endHours <= 0 && endMin <= 0) {
      // this.isWatchEventDisable = true;
    }

    
    if (startDays <= 0 && startHours <= 0 && startMin <= 14) {
      this.isTimeLeftLessOrEqual15 = true;
    } else {
      this.isTimeLeftLessOrEqual15 = false;
    }
    this.isTimeLeftLessOrEqual15 = true;

    setTimeout(() => {
      this.setTimer()
    }, 1000);
  }

  private async setStreamDetails() {
    let data = this.storageService.get(AppStorage.data, true);
    this.userData = JSON.parse(data);
  /*  try {
      let result = await this.streamDetailsService.getStreamDetails().toPromise();
     // console.log("res===", result);
      if (result.app.status) {
        let url = result.app.all_streams.current_page_stream.stream_trailerUrl;
        let trailerUrl = url.replace("https:", "");
        let stUrl = result.app.all_streams.current_page_stream.stream_url;
        let streamUrl = stUrl; //.replace("https:", "");
        this.streamDetails = {
          eventStartDateTime: moment(result.app.all_streams.current_page_stream.stream_event_st_date_time_on, ["MM/DD/YYYY h:mm A"]).format("YYYY-MM-DDTHH:mm:ss"),
          eventEndDateTime: moment(result.app.all_streams.current_page_stream.stream_event_end_date_time_on, ["MM/DD/YYYY h:mm A"]).format("YYYY-MM-DDTHH:mm:ss"),
          eventTimeZone: result.app.all_streams.current_page_stream.stream_event_timezone_Offset,
          eventTrailerUrl: trailerUrl,
          eventStreamUrl: streamUrl,
          eventVideoPlayer: "vimeo"
        }
        this.storageService.set(AppStorage.streamDetails, JSON.stringify(this.streamDetails), true);
        setTimeout(() => {
          this.showLoading = false;
          this.setTimer();
        }, 1000);
      }
    } catch (error) {
      console.log('error: ', error);
      this.showLoading = false;
    }*/
  }

  private getTicketCodeMessage(): void {

    this.subs.sink = this.fetchJsonService.getMessagesList()
      .subscribe((messageList) => {
        this.ticketCodeMessage = messageList[MessageFor.TicketCodeForm];
      });
  }

  private createTicketCodeFormGroup(): void {
    this.ticketCodeFormGroup = this.formBuilder.group({
      activationcode: [null, [Validators.required]],
    });
  }

  //#endregion

  //#region public methods

  public get ticketCodeControls() { return this.ticketCodeFormGroup.controls; }

  public openSupportModal(): void {
    this.modalRef = this.modalService.show(
      SupportComponent,
      Object.assign({ ignoreBackdropClick: true }, { class: 'gray modal-lg' })
    );
  }

  public openResendCodeModal(): void {
    this.modalRef = this.modalService.show(
      ResendCodeComponent,
      Object.assign({ ignoreBackdropClick: true }, { class: 'gray modal-lg' })
    );
  }

  public async onSubmitClick() {
    //alert("ewr");
   /* if (this.ticketCodeFormGroup.invalid) {
      this.showLoading = false;
      this.errorMessage = "Please enter Password.";
      return;
    }
*/
    this.showLoading = true;
    /*this.isCheckValidation = true;

    let ticketCode = this.ticketCodeControls.activationcode.value;
    var tickCd = this.ticketCodeControls.activationcode.value;
    ticketCode = ticketCode.trim();
*/

    setTimeout(() => {
      let eventData = this.storageService.get(AppStorage.streamDetails, true);
      this.streamDetails = JSON.parse(eventData);
      
      
        this.modalRef = this.modalService.show(
          VimeoPlayerComponent,
          Object.assign({ ignoreBackdropClick: true }, { class: 'green vimeoPlayerWidth  modal-lg h-100 mb-0' })
        );
      

      
      
      this.showLoading = false;
      this.errorMessage = "";
    }, 1000);
  
    
  }


  public async onQNASubmitClick() {
   
    this.showLoading = true;
   

    setTimeout(() => {
      let eventData = this.storageService.get(AppStorage.streamDetails, true);
      this.streamDetails = JSON.parse(eventData);
      
     
        this.modalRef = this.modalService.show(
          LiveVideoPlayerComponent,
          Object.assign({ ignoreBackdropClick: true }, { class: 'gray vimeoPlayerWidth modal-lg h-100 mb-0' })
        );

      
      
      this.showLoading = false;
      this.errorMessage = "";
    }, 1000);
  
    
  }

  //#endregion

}
