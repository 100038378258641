import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { StorageService } from './storage.service';
import { APP_ID, APP_URLS, MENU_GUID, STREAM_GUID } from '../../providers/constant';
import { AppStorage } from '../../providers/enum';

@Injectable({
  providedIn: 'root'
})
export class StreamDetailsService {

  private streamDetailApiUrl = `${APP_URLS.WEB_API_URL}`;

  constructor(
    private http: HttpClient
  ) { }

  public getStreamDetails(): Observable<any> {
    return this.http.get<any>(`${this.streamDetailApiUrl}/feed/v1/stream_detail/${STREAM_GUID}/${MENU_GUID}/${MENU_GUID}`);
  }

  public trackUserData(): Observable<any> {
    return this.http.get<any>(`${this.streamDetailApiUrl}/track-user-c2c.php?appCode=${APP_ID}`);
  }
}
