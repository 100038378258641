import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap';
import { SubSink } from 'subsink';
import { StaticTicketCode } from '../providers/constant';

import { MessageFor } from '../providers/enum';
import { CommonUtilsService } from '../shared/services/common-utils.service';
import { FetchJsonService } from '../shared/services/fetch-json.service';
import { ResendCodeService } from './services/resend-code.service';

@Component({
  selector: 'app-resend-code',
  templateUrl: './resend-code.component.html',
  styleUrls: ['./resend-code.component.css']
})
export class ResendCodeComponent implements OnInit {

  //#region variables

  public resendCodeMessage: any;
  public isCheckValidation = false;
  public errorMessage: string = '';
  public showLoading = false;
  public userData: any;

  public resendCodeFormGroup: FormGroup;

  private subs = new SubSink();

  //#endregion

  //#region life-cycle hook

  constructor(
    private fetchJsonService: FetchJsonService,
    private formBuilder: FormBuilder,
    private resendCodeService: ResendCodeService,
    private modalRef: BsModalRef,) { }

  ngOnInit(): void {
    this.getResendCodeFormMessage();
    this.createResendCodeFormGroup();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  //#endregion

  //#region private methods

  private getResendCodeFormMessage(): void {

    this.subs.sink = this.fetchJsonService.getMessagesList()
      .subscribe((messageList) => {
        this.resendCodeMessage = messageList[MessageFor.ResendCodeForm];
      });
  }

  private createResendCodeFormGroup(): void {
    this.resendCodeFormGroup = this.formBuilder.group({
      email: [null, [Validators.required, CommonUtilsService.checkEmail]],
    }, {
      validator: [
        CommonUtilsService.checkEmail('email', true)
      ]
    });
  }

  //#endregion

  //#region public methods

  public get resendCodeControls() { return this.resendCodeFormGroup.controls; }

  public onCancel(): void {
    this.modalRef.hide();
  }

  public onSubmitClick(): void {
    if (this.resendCodeFormGroup.invalid) {
      return;
    }

    this.showLoading = true;
    this.isCheckValidation = true;

    let messageContent = `
    <table width="100%" cellpadding="0" cellspacing="0" bgcolor="#EEE" style="color:rgb(0,0,0);border:1px solid rgb(221,221,221)">
      <tbody>
        <tr>
          <td style="font-family:Arial,Helvetica,sans-serif;padding:15px;font-size:12px;line-height:1.3;text-align:justify">
            Hello,
            <br>
            <br>
            <b>GET READY TO ROCK!!!</b>
            <br>
            <br>
            <b>The CHORDS2CURE: HOME SWEET HOME CONCERT</b>&nbsp;is on,
            <br>
            <b>
              <br>Sunday, October 25th&nbsp;&nbsp;10:00am PDT
              <br>Sunday, October 25th&nbsp; 6:00pm PDT&nbsp;
              <br>Wednesday, October 28th&nbsp; 8:00pm PDT
            </b>&nbsp;
            <br>
            <br>
            <b>PASSWORD:</b>&nbsp;<b>${StaticTicketCode}</b>
            <br>
            <br>Please donate at:&nbsp;<a href="https://www.chords2cure.org/" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.chords2cure.org/&amp;source=gmail&amp;ust=1602613760025000&amp;usg=AFQjCNG22Nmfl7aQcAIdETTNtmZMyUgm3A">www.chords2cure.org</a>
            <br>
            <br>
            <b>*Please visit us with your password 15 minutes before the scheduled start time!</b>
            <br>
            <b>**FOR TECHNICAL SUPPORT, PLEASE CALL 805-317-4608 or email&nbsp;<a href="mailto:Chords2Cure1@gmail.com" target="_blank">Chords2Cure1@gmail.com</a></b>
            <br><br><br>Best,<br>The Chords2Cure Team</td></tr></tbody></table>`;

    let obj = {
      email: this.resendCodeControls.email.value,
      message: messageContent
    }
    console.log('obj: ', obj);
    this.subs.sink = this.resendCodeService.resendTicketCode(obj)
      .subscribe(
        (response: any) => {
          console.log('response: ', response);
          if (response.status === "failed") {
            this.errorMessage = 'Oops, something went wrong please try again later.'
          } else {
            this.errorMessage = 'Password has been sent to your email address successfully, Thank you.'
          }
          this.showLoading = false;
        }, (error) => {
          console.log('error: ', error);
          this.errorMessage = 'Oops, something went wrong please try again later.'
          this.showLoading = false;
        });
  }

  //#endregion

}
