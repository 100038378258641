import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import Player from '@vimeo/player';

@Component({
  selector: 'app-vimeo-player',
  templateUrl: './vimeo-player.component.html',
  styleUrls: ['./vimeo-player.component.css']
})
export class VimeoPlayerComponent implements OnInit {

  constructor(private modalRef: BsModalRef) { }

  ngOnInit() {

    var options = {   
      url: "https://vimeo.com/644887462", //"https://vimeo.com/544674908", //https://vimeo.com/535582526",https://vimeo.com/545202263/145f6c9602
     
      autoplay: true,
      muted: false,
      loop: true,
      responsive: true
    };
    
    var videoPlayer = new Player('myVimeoVideo', options);
    // videoPlayer.autoplay = true;
   // alert("vid="+videoPlayer);
    videoPlayer.on('play', function() {
      console.log('Played the video');
      //alert("dddd");
    });
  }

  public onCancel(): void {
   
    this.modalRef.hide();
   
  }

}
