/** App Storage used for get stored application Local Storage */
export enum AppStorage {
    data = 'data',
    isLiveEventBuyed = 'isLiveEventBuyed',
    streamDetails = 'streamDetails'
}

/** Message used for get form name */
export enum MessageFor {
    LoginForm = 'loginForm',
    RegisterForm = 'registerForm',
    AccountActivationFrom = 'accountActivationFrom',
    DonatePerViewModal = 'donatePerViewModal',
    ChangePasswordForm = 'changePasswordForm',
    ForgotPasswordForm = 'forgotPasswordForm',
    TicketCodeForm = 'ticketCodeForm',
    SupportForm = 'supportForm',
    ResendCodeForm = 'resendCodeForm',
}