import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, APP_BASE_HREF } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AppRoutingModule } from './app-routing.module';
import { SpinnerComponent } from './spinner/spinner.component';
import { SupportComponent } from './support/support.component';
import { SharedModule } from './shared/shared.module';
import { LiveVideoPlayerComponent } from './live-video-player/live-video-player.component';
import { ResendCodeComponent } from './resend-code/resend-code.component';
import { VimeoPlayerComponent } from './vimeo-player/vimeo-player.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SpinnerComponent,
    SupportComponent,
    LiveVideoPlayerComponent,
    ResendCodeComponent,
    VimeoPlayerComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    AppRoutingModule,
    SharedModule
  ],
  providers: [],
  entryComponents: [SupportComponent, LiveVideoPlayerComponent, ResendCodeComponent, VimeoPlayerComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
