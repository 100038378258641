import { environment } from '../../environments/environment';
export class APP_URLS {
    static WEB_API_URL: string = environment.WEB_API_URL;
    static SUPPORT_API_URL: string = environment.SUPPORT_API_URL;
}


// TEST APP DETAILS : encore.chords2cure.tv
export const APP_ID = "7f912b2a598f9397d282950787b6b9d0"; 
export const STREAM_GUID = "8a000bccb9ee11c7258cf09f279e16b4";
export const MENU_GUID = "0d33cf157d8822d6d831e2559dcfde4f"; 


/*
// CHORDS2CURE APP DETAILS : concert.chords2cure.tv
export const APP_ID = "3e909131cbb1a1f308183c838bc005d7"; 
export const STREAM_GUID = "d8996cfd878425e08814349b21cf9b19";
export const MENU_GUID = "caee71a3635e3c764d7f781054341b50";
*/

export const StaticTicketCode = "CELEB2021";

export const IP_ADDRESS_REGEX = /^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/;
