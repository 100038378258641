import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AppStorage } from '../providers/enum';
import Player from '@vimeo/player';
import { StorageService } from '../shared/services/storage.service';

// declare const videojs: any;
declare const Clappr: any;
declare const p2pml: any;
declare const Hls: any;

@Component({
  selector: 'app-live-video-player',
  templateUrl: './live-video-player.component.html',
  styleUrls: ['./live-video-player.component.scss']
})
export class LiveVideoPlayerComponent implements OnInit, OnDestroy {

  //#region variables

  public player;
  public streamDetails: any;

  //#endregion

  //#region life-cycle hook

  constructor(private modalRef: BsModalRef,
    private storageService: StorageService) { }

  ngOnInit(): void {
    var options = {   
      url: "https://vimeo.com/644652309", //"https://vimeo.com/544674908", //https://vimeo.com/535582526",https://vimeo.com/545202263/145f6c9602
     
      
      autoplay: true,
      muted: false,
      loop: true,
      responsive: true
    };
    
    var videoPlayer = new Player('myVimeoVideo', options);
    // videoPlayer.autoplay = true;
   // alert("vid="+videoPlayer);
    videoPlayer.on('play', function() {
      //console.log('Played the video');
      //alert("dddd");
    });
  }

  ngOnDestroy(): void {
  }

  //#endregion

  //#region public methods

  public onCancel(): void {
    if (this.player) {
     // this.player.dispose();
    //  this.player
    }
    this.modalRef.hide();
   
  }

  //#endregion

}
