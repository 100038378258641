import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { APP_URLS } from '../../providers/constant';

@Injectable({
  providedIn: 'root'
})
export class ResendCodeService {

  private supportApiUrl = `${APP_URLS.SUPPORT_API_URL}`;

  constructor(private http: HttpClient) { }

  public resendTicketCode(resendCodeObject: any): Observable<any> {
    return this.http.post<any>(`${this.supportApiUrl}/resendTicketCode`, resendCodeObject);
  }

}
