import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap';
import { SubSink } from 'subsink';

import { MessageFor } from '../providers/enum';
import { CommonUtilsService } from '../shared/services/common-utils.service';
import { FetchJsonService } from '../shared/services/fetch-json.service';
import { SupportService } from './services/support.service';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportComponent implements OnInit {

  //#region variables

  public supportMessage: any;
  public isCheckValidation = false;
  public errorMessage: string = '';
  public showLoading = false;
  public userData: any;

  public supportFormGroup: FormGroup;

  private subs = new SubSink();

  //#endregion

  //#region life-cycle hook

  constructor(
    private fetchJsonService: FetchJsonService,
    private formBuilder: FormBuilder,
    private supportService: SupportService,
    private modalRef: BsModalRef,) { }

  ngOnInit(): void {
    this.getSupportFormMessage();
    this.createSupportFormGroup();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  //#endregion

  //#region private methods

  private getSupportFormMessage(): void {

    this.subs.sink = this.fetchJsonService.getMessagesList()
      .subscribe((messageList) => {
        this.supportMessage = messageList[MessageFor.SupportForm];
      });
  }

  private createSupportFormGroup(): void {
    this.supportFormGroup = this.formBuilder.group({
      name: [null, Validators.required],
      email: [null, [Validators.required, CommonUtilsService.checkEmail]],
      message: [null, Validators.required],
    }, {
      validator: [
        CommonUtilsService.checkEmail('email', true)
      ]
    });
  }

  //#endregion

  //#region public methods

  public get supportControls() { return this.supportFormGroup.controls; }

  public onCancel(): void {
    this.modalRef.hide();
  }

  public onSubmitClick(): void {
    if (this.supportFormGroup.invalid) {
      return;
    }

    this.showLoading = true;
    this.isCheckValidation = true;

    let obj = {
      name: this.supportControls.name.value,
      email: this.supportControls.email.value,
      message: this.supportControls.message.value
    }
    this.subs.sink = this.supportService.sendSupportMail(obj)
      .subscribe(
        (response: any) => {
          console.log('response: ', response);
          if (response.status === "failed") {
            this.errorMessage = 'Oops, something went wrong please try again later.'
          } else {
            this.errorMessage = 'Your request has been submitted successfully, Thank you.'
          } 
          this.showLoading = false;
        }, (error) => {
          console.log('error: ', error);
          this.errorMessage = 'Oops, something went wrong please try again later.'
          this.showLoading = false;
        });
  }

  //#endregion

}
